<template>
    <div class="c-agents pb-5">
        <v-title title="Agents"></v-title>
        <title-box>
            <div class="row">
                <div class="col-9">
                    <h1>Agents</h1>
                </div>
                <div class="col-3 text-right">
                    <mercur-button v-if="hasPermission('MerchantCentral/createAgent')" :to="{ name: 'AddAgent' }" class="btn btn-primary text-uppercase">
                        <i class="fas fa-plus"></i> Add new agent
                    </mercur-button>
                </div>
            </div>
        </title-box>

        <div class="container-fluid">
            <template v-if="hasPermission('MerchantCentral/listAccounts')">
                <div class="row mt-3">
                    <div class="col-6"><quick-search :quickSearch.sync="filters.search" /></div>
                    <div class="col-6"><pagination :pagination="pagination" @change="changeAmountOfItems" /></div>
                </div>
                <merchant-data-table class="shadow" :options="options" :quickSearch="filters.search" :url="url" ref="table" @paginationSet="updatePagination" :isNotApplicationBound="true"></merchant-data-table>
            </template>
            <p v-else>Not allowed to see this view</p>
        </div>

        <mercur-dialog :is-open.sync="dialog.isOpen">
            <div class="mt-3 mb-1">{{ dialog.content }}</div>
            <template #footer>
                <div class="text-right">
                    <button class="btn" @click="dialog.isOpen = false">Cancel</button>
                    <button class="btn" @click="dialogAction" :disabled="isLoading">Confirm</button>
                </div>
            </template>
        </mercur-dialog>
     </div>
</template>

<script>
import CONFIG from '@root/config'

import MerchantDataTable from '@/components/elements/MerchantDataTable'
import Pagination from '@/components/elements/table/Pagination'
import QuickSearch from '@/components/elements/table/QuickSearch'
export default {
    name: 'AgentsView',
    components: { MerchantDataTable, Pagination, QuickSearch },
    data () {
        return {
            url: CONFIG.API.ROUTES.AGENTS.OVERVIEW,
            activeAccount: null,
            pagination: {},
            amountOfDisplayedItems: 50,
            isLoading: false,
            dialog: {
                isOpen: false,
            },
            filters: {
                search: '',
            },
            options: {
                columns: {
                    'Name': {
                        field: ['firstName', 'lastName'],
                        link: (value, data) => {
                            return {
                                name: 'EditAgent',
                                params: {
                                    agentId: data.accountId,
                                },
                            }
                        },
                        sortable: true,
                    },
                    'last Name': { field: 'lastName', hide: true },
                    'Email': { field: 'email', sortable: true },
                    'Account Type': { field: 'accountType', filter: false, width: 100 },
                    'Organisation': { field: 'organisationName', filter: false },
                    'Revoked': { field: 'dateRevoked', filter: false },
                },
                quickSearchColumns: ['firstName', 'lastName', 'email', 'companyEmail', 'companyCocNumber'],
                actions: {
                    type: 'dropdown',
                    items: [
                        {
                            text: 'Edit',
                            to: ({ data }) => {
                                return {
                                    name: 'EditAgent',
                                    params: {
                                        agentId: data.accountId,
                                    },
                                }
                            },
                        },
                        {
                            text: 'Revoke',
                            isHidden: ({ data }) => {
                                return data.dateRevoked !== null
                            },
                            onClick: ({ data }) => {
                                this.triggerRevokeDialog(data)
                            },
                            isDisabled: () => {
                                return this.isLoading
                            },
                        },
                        {
                            text: 'Reactivate',
                            isHidden: ({ data }) => {
                                return data.dateRevoked === null
                            },
                            onClick: ({ data }) => {
                                this.triggerReactivateDialog(data)
                            },
                            isDisabled: () => {
                                return this.isLoading
                            },
                        },
                    ],
                },
                paginationType: 'custom',
                paginationPageSize: 50,
                cacheBlockSize: 50,
                defaultColDef: { menuTabs: ['generalMenuTab'] },
            },
        }
    },
    methods: {
        triggerRevokeDialog (data) {
            this.activeAccount = data.accountId
            this.dialog = {
                isOpen: true,
                content: `Are you sure that you want to revoke access for ${data.firstName} ${data.lastName}?`,
                action: 'REVOKE',
            }
        },
        triggerReactivateDialog (data) {
            this.activeAccount = data.accountId
            this.dialog = {
                isOpen: true,
                content: `Are you sure that you want to reactivate access for ${data.firstName} ${data.lastName}?`,
            }
        },
        dialogAction () {
            if (this.dialog.action === 'REVOKE') {
                this.revoke()
            } else {
                this.reactivate()
            }
        },
        revoke () {
            const url = CONFIG.API.ROUTES.AGENTS.REVOKE.replace('{agentId}', this.activeAccount)
            this.isLoading = true
            this.post(url, {}, 'Revoke success').then(() => {
                this.$refs.table.refreshGrid()
                this.dialog = {
                    isOpen: false,
                }
            }).finally(() => {
                this.isLoading = false
            })
        },

        reactivate () {
            const url = CONFIG.API.ROUTES.AGENTS.REACTIVATE.replace('{agentId}', this.activeAccount)
            this.isLoading = true
            this.post(url, {}, 'Reactivate success').then(() => {
                this.$refs.table.refreshGrid()
                this.dialog = {
                    isOpen: false,
                }
            }).finally(() => {
                this.isLoading = false
            })
        },
        applyFilter (data) {
            this.$refs.table.applyFilter(data)
        },
        updatePagination (data) {
            this.$set(this, 'pagination', data)
            this.pagination.defaultAmount = this.amountOfDisplayedItems
        },
        changeAmountOfItems (value) {
            this.$refs.table.setPageSize(value)
            this.amountOfDisplayedItems = value
            this.$refs.table.refreshGrid()
        },
    },
}
</script>
